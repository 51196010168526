<template>
  <div class="container">
    <h4 class="mt-4 text-center">Проверка файла на плагиат</h4>

    <div class="mt-4 row">
      <div class="col-md-3">
        <input class="form-control" type="file" @change="handleFileUpload"/>
      </div>
    </div>
    <div class="mt-3">
      <button class="btn btn-primary" @click="sendForCheck" :disabled="loading">
        <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        {{ loading ? 'Отправка' : 'Отправить на проверку' }}
      </button>
    </div>

    <div class="mt-5 mb-3" v-if="fileId">
      <h4 class="mb-3 text-center">Результат</h4>
      <DataTable :value="antiplagiatResults" :paginator="true" :rows="20"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[20, 50, 100]"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="file" header="Файл"></Column>

        <Column header="Результат">
          <template #body="{data}">
            {{data.result}}
          </template>
        </Column>

      </DataTable>
    </div>

  </div>
</template>

<script>

  import httpClient from "../../services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: 'Antiplagiat',
    data() {
      return {
        interval: null,
        file: null,
        loading: false,
        antiplagiatResults: [
          {
            file: 'fileName',
            result: '100% оригинальность'
          }
        ],
        fileId: null
      }
    },
    methods: {
      handleFileUpload(e) {
        this.file = e.target.files[0]
      },
      async sendForCheck() {
        this.loading = true

        const formData = new FormData();
        formData.append('file', this.file);
        console.log(Object.fromEntries(formData), 'formData')

        try {
          const url = `plagiarism/plagiarism-file/send-to-check?access-token=${getCookie('ACCESS_TOKEN')}`
          const {status, data} = await httpClient.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
          if (status === 200) {
            this.$message({text: 'Файл успешно оправлен на проверку'})
            this.fileId = data
            this.interval = setInterval(() => {
              this.getAntiplagiatResults()
            }, 30000)
            console.log(data, 'send file success')
          }
        } catch (e) {
          console.log(e)
          this.$error({title: 'При отправке произошла ошибка', text: e})
        }


        this.loading = false
      },
      async getAntiplagiatResults() {
        if (this.fileId) {
          try {
            const url = `plagiarism/plagiarism-file/index?access-token=${getCookie('ACCESS_TOKEN')}&file_id=${this.fileId}`
            const {status, data} = await httpClient.get(url);
            if (status === 200) {
              console.log(data)
            }
          } catch (e) {
            console.log(e.response);
          }
        }
      }

    },
    async mounted() {
      this.loading = false;
    },
    beforeUnmount() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }
</script>
<style scoped>

</style>